import { useEffect, useState } from 'react'
import { Button, Card, Flex, Table } from 'antd'
import { useNavigate } from 'react-router-dom'
import { PlusOutlined } from '@ant-design/icons'

import apiClient, { handleApiError } from '../../services/apiClient'
import generateColumns from './generateColumns'
import ModalDeleteConfirmation from './ModalDeleteConfirmation'
import ModalEdit from './ModalEdit'

const CRUDTableComponent = ({
  entityName,
  tableColumns,
  entityUrl,
  tableColumnsActions,
}) => {
  if (!entityName) {
    throw new Error('entityName is required')
  }
  if (!tableColumns) {
    throw new Error('tableColumns is required')
  }
  if (!entityUrl) {
    throw new Error('entityUrl is required')
  }

  const [selectedRecord, setSelectedRecord] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [entityList, setEntityList] = useState([])

  const navigate = useNavigate()

  const fetchEntityList = async entityUrl => {
    setIsLoading(true)
    try {
      const { data } = await apiClient.get(entityUrl)

      setEntityList(
        data.map(entity => ({
          ...entity,
          key: entity.id,
        }))
      )
    } catch (error) {
      handleApiError({ error })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!entityUrl) {
      throw new Error('entityUrl is required')
    }
    fetchEntityList(entityUrl)
  }, [entityUrl])

  const onDelete = record => {
    setSelectedRecord(record)
    setIsDeleteModalOpen(true)
  }

  const onEdit = record => {
    setSelectedRecord(record)
    setIsEditModalOpen(true)
  }

  const actions = tableColumnsActions || { edit: true, delete: true }
  const columns = generateColumns(
    tableColumns,
    onDelete,
    onEdit,
    actions,
    entityList
  )

  return (
    <>
      <Card>
        <Flex align="center" justify="space-between">
          <h1>{entityName.plural}</h1>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => navigate('create')}
          >
            Crear {entityName.singular}
          </Button>
        </Flex>

        <Table columns={columns} dataSource={entityList} loading={isLoading} />
      </Card>
      <ModalDeleteConfirmation
        entityName={entityName}
        entityUrl={entityUrl}
        selectedRecordId={selectedRecord?.id}
        isModalOpen={isDeleteModalOpen}
        setIsModalOpen={setIsDeleteModalOpen}
        fetchEntityList={fetchEntityList}
      />
      <ModalEdit
        entityName={entityName}
        entityUrl={entityUrl}
        selectedRecord={selectedRecord}
        isModalOpen={isEditModalOpen}
        setIsModalOpen={setIsEditModalOpen}
        fields={tableColumns}
        fetchEntityList={fetchEntityList}
      />
    </>
  )
}

export default CRUDTableComponent
