import { Card, Col, Flex, Form, InputNumber, Row } from 'antd'

const GoalComponent = ({ findErrorByKey }) => {
  return (
    <Card
      style={{
        marginBottom: '1rem',
        borderRadius: '10px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
      }}
    >
      <b>Perfil de Composición del Cuerpo (Metas Personales)</b>
      <div style={{ paddingTop: 30 }}>
        <Flex direction="vertical" justify="space-between" gap="large">
          <div style={{ width: '100%' }}>
            <Row
              gutter={[16, 16]}
              style={{
                marginBottom: '1rem',
                borderBottom: '1px solid #f0f0f0',
              }}
              align="middle"
            >
              <Col span={8}>
                <p style={{ marginBottom: 0, textAlign: 'left' }}>
                  % de Masa Grasa:
                </p>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Actual"
                  name="actualBodyFatPercentage"
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    disabled
                    addonAfter="%"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Meta"
                  name="bodyFatPercentageGoal"
                  {...findErrorByKey('bodyFatPercentageGoal')}
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    addonAfter="%"
                    min={0}
                    max={100}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row
              gutter={[16, 16]}
              style={{
                marginBottom: '1rem',
                borderBottom: '1px solid #f0f0f0',
              }}
              align="middle"
            >
              <Col span={8}>
                <p style={{ marginBottom: 0, textAlign: 'left' }}>
                  % de Masa Magra:
                </p>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Actual"
                  name="actualLeanBodyMassPercentage"
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    disabled
                    addonAfter="%"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Meta" name="leanBodyMassPercentageGoal">
                  <InputNumber
                    style={{ width: '100%' }}
                    addonAfter="%"
                    disabled
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row
              gutter={[16, 16]}
              style={{
                marginBottom: '1rem',
                borderBottom: '1px solid #f0f0f0',
              }}
              align="middle"
            >
              <Col span={8}>
                <p style={{ marginBottom: 0, textAlign: 'left' }}>
                  Peso de Masa Grasa:
                </p>
              </Col>
              <Col span={8}>
                <Form.Item label="Actual" name="actualBodyFatWeight">
                  <InputNumber
                    style={{ width: '100%' }}
                    disabled
                    addonAfter="kg"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Meta" name="bodyFatWeightGoal">
                  <InputNumber
                    style={{ width: '100%' }}
                    addonAfter="kg"
                    disabled
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row
              gutter={[16, 16]}
              style={{ marginBottom: '1rem' }}
              align="middle"
            >
              <Col span={8}>
                <p style={{ marginBottom: 0, textAlign: 'left' }}>
                  Peso de Masa Magra:
                </p>
              </Col>
              <Col span={8}>
                <Form.Item label="Actual" name="actualLeanBodyMassWeight">
                  <InputNumber
                    style={{ width: '100%' }}
                    disabled
                    addonAfter="kg"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Meta" name="leanBodyMassWeightGoal">
                  <InputNumber
                    style={{ width: '100%' }}
                    addonAfter="kg"
                    disabled
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div style={{ width: '100%' }}>
            <Row
              gutter={[16, 16]}
              style={{
                marginBottom: '1rem',
                borderBottom: '1px solid #f0f0f0',
              }}
              align="middle"
            >
              <Col span={8}>
                <p style={{ marginBottom: 0, textAlign: 'left' }}>
                  Peso Total Cuerpo:
                </p>
              </Col>
              <Col span={8}>
                <Form.Item label="Actual" name="actualTotalBodyWeight">
                  <InputNumber
                    style={{ width: '100%' }}
                    disabled
                    addonAfter="kg"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Meta" name="weightGoal">
                  <InputNumber
                    style={{ width: '100%' }}
                    addonAfter="kg"
                    disabled
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Flex>
      </div>
    </Card>
  )
}

export default GoalComponent
