import CRUDTableComponent from '../../components/CRUDTableComponent'

const ConsultationReasonsTable = () => {
  const tableColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Motivo',
      dataIndex: 'reason',
      type: 'string',
      sorter: (a, b) => a.reason.localeCompare(b.reason),
    },
  ]

  return (
    <CRUDTableComponent
      entityName={{
        singular: 'Motivo de consulta',
        plural: 'Motivos de consulta',
      }}
      tableColumns={tableColumns}
      entityUrl="/consultation-reasons?sortBy=reason"
    />
  )
}

export default ConsultationReasonsTable
