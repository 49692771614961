import CRUDTableComponent from '../../components/CRUDTableComponent'

const BodyFatRecommendationsTable = () => {
  const tableColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Edad desde',
      dataIndex: 'ageFrom',
      type: 'number',
      sorter: (a, b) => a.ageFrom - b.ageFrom,
    },
    {
      title: 'Edad hasta',
      dataIndex: 'ageTo',
      type: 'number',
      sorter: (a, b) => a.ageTo - b.ageTo,
    },
    {
      title: 'Masculino',
      dataIndex: 'maleBodyFat',
      type: 'number',
      sorter: (a, b) => a.maleBodyFat - b.maleBodyFat,
    },
    {
      title: 'Femenino',
      dataIndex: 'femaleBodyFat',
      type: 'number',
      sorter: (a, b) => a.femaleBodyFat - b.femaleBodyFat,
    },
  ]

  return (
    <CRUDTableComponent
      entityName={{
        singular: 'Registro de grasa corporal',
        plural: 'Registros de grasa corporal',
      }}
      tableColumns={tableColumns}
      entityUrl="/recommended-body-fat?sortBy=ageFrom"
    />
  )
}

export default BodyFatRecommendationsTable
