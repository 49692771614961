import {
  UserOutlined,
  EnvironmentOutlined,
  DashboardOutlined,
  BankOutlined,
  CommentOutlined,
  MedicineBoxOutlined,
  ToolOutlined,
  ExperimentOutlined,
  HeartOutlined,
  LogoutOutlined,
  SearchOutlined,
  BugOutlined,
} from '@ant-design/icons'
import { Layout, Menu, Tooltip } from 'antd'
import { useNavigate, useLocation } from 'react-router-dom'

const { Sider } = Layout

const SideMenu = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const getSelectedKey = () => {
    const pathToKeyMap = {
      '/professions': '1',
      '/communes': '2',
      '/physical-activity-levels': '3',
      '/health-insurances': '4',
      '/consultation-reasons': '5',
      '/medications': '6',
      '/surgeries': '7',
      '/body-fat-recommendations': '8',
      '/physical-exams': '9',
      '/lab-exams': '10',
      '/diseases': '11',
    }
    return pathToKeyMap[location.pathname] || '1'
  }

  const handleLogout = () => {
    localStorage.removeItem('authToken')
    navigate('/sign-in')
  }

  return (
    <Sider width={260} style={{ height: '100vh', position: 'fixed', left: 0, zIndex: 99 }}>
      <div
        style={{
          display: 'flex',
          height: '100%',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <div className="demo-logo" />
          <h1 style={{ color: '#fff', textAlign: 'center' }}>
            Diet Center Cloud
          </h1>
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={[getSelectedKey()]}
            items={[
              {
                key: '0',
                label: 'Pacientes',
                icon: <UserOutlined />,
                onClick: () => navigate('/patients'),
              },
              {
                key: '1',
                label: (
                  <Tooltip title="Configuración del sistema" placement="right">
                    Configuración del sistema
                  </Tooltip>
                ),
                children: [
                  {
                    key: '2',
                    label: 'Profesiones',
                    icon: <UserOutlined />,

                    onClick: () => navigate('/professions'),
                  },
                  {
                    key: '3',
                    label: (
                      <Tooltip title="Comunas" placement="right">
                        Comunas
                      </Tooltip>
                    ),
                    icon: <EnvironmentOutlined />,
                    onClick: () => navigate('/communes'),
                  },
                  {
                    key: '4',
                    label: (
                      <Tooltip
                        title="Niveles de actividad física"
                        placement="right"
                      >
                        Niveles de actividad física
                      </Tooltip>
                    ),
                    icon: <DashboardOutlined />,
                    onClick: () => navigate('/physical-activity-levels'),
                  },
                  {
                    key: '5',
                    label: 'Isapres',
                    icon: <BankOutlined />,
                    onClick: () => navigate('/health-insurances'),
                  },
                  {
                    key: '6',
                    label: 'Motivo de consultas',
                    icon: <CommentOutlined />,
                    onClick: () => navigate('/consultation-reasons'),
                  },
                  {
                    key: '7',
                    label: 'Medicamentos',
                    icon: <MedicineBoxOutlined />,
                    onClick: () => navigate('/medications'),
                  },
                  {
                    key: '8',
                    label: 'Cirugías',
                    icon: <ToolOutlined />,
                    onClick: () => navigate('/surgeries'),
                  },

                  {
                    key: '9',
                    label: (
                      <Tooltip
                        title="Grasa corporal recomendada por género y edad"
                        placement="right"
                      >
                        Grasa corporal recomendada por género y edad
                      </Tooltip>
                    ),
                    icon: <HeartOutlined />,
                    onClick: () => navigate('/body-fat-recommendations'),
                  },
                  {
                    key: '10',
                    label: 'Exámenes físicos',
                    icon: <SearchOutlined />,
                    onClick: () => navigate('/physical-exams'),
                  },
                  {
                    key: '11',
                    label: 'Exámenes de laboratorio',
                    icon: <ExperimentOutlined />,
                    onClick: () => navigate('/lab-exams'),
                  },
                  {
                    key: '12',
                    label: 'Enfermedades',
                    icon: <BugOutlined />,
                    onClick: () => navigate('/diseases'),
                  },
                ],
              },
            ]}
          />
        </div>
        <Menu
          theme="dark"
          mode="inline"
          items={[
            {
              key: 'logout',
              label: 'Logout',
              icon: <LogoutOutlined />,
              onClick: () => handleLogout(),
            },
          ]}
        ></Menu>
      </div>
    </Sider>
  )
}

export default SideMenu
