import dayjs from 'dayjs'
import { Button } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { EyeOutlined } from '@ant-design/icons'

import CRUDTableComponent from '../../components/CRUDTableComponent'
import apiClient from '../../services/apiClient'

const PatientsTable = () => {
  const [communes, setCommunes] = useState([])
  const [healthInsurances, setHealthInsurances] = useState([])
  const [physicalActivityLevels, setPhysicalActivityLevels] = useState([])
  const [professions, setProfessions] = useState([])

  const fetchInformation = async () => {
    try {
      const [communes, healthInsurances, physicalActivityLevels, professions] =
        await Promise.all([
          apiClient.get('/communes'),
          apiClient.get('/health-insurances'),
          apiClient.get('/physical-activity-levels'),
          apiClient.get('/professions'),
        ])

      setCommunes(communes.data)
      setHealthInsurances(healthInsurances.data)
      setPhysicalActivityLevels(physicalActivityLevels.data)
      setProfessions(professions.data)
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    fetchInformation()
  }, [])

  const navigate = useNavigate()
  const tableColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Nombre',
      dataIndex: 'firstName',
      type: 'string',
      filter: true,
    },
    {
      title: 'Segundo Nombre',
      dataIndex: 'secondName',
      type: 'string',
      filter: true,
    },
    {
      title: 'Apellido',
      dataIndex: 'firstLastName',
      type: 'string',
      filter: true,
    },
    {
      title: 'Apellido Materno',
      dataIndex: 'secondLastName',
      type: 'string',
      filter: true,
    },
    {
      title: 'Comuna',
      dataIndex: 'communeId',
      displayOnTable: false,
      type: 'select',
      options: communes.map(({ id, name }) => ({
        label: name,
        value: id,
      })),
    },
    {
      title: 'Previsión',
      dataIndex: 'healthInsuranceId',
      displayOnTable: false,
      type: 'select',
      options: healthInsurances.map(({ id, name }) => ({
        label: name,
        value: id,
      })),
    },
    {
      title: 'Nivel de actividad física',
      dataIndex: 'physicalActivityLevelId',
      displayOnTable: false,
      type: 'select',
      options: physicalActivityLevels.map(({ id, level }) => ({
        label: level,
        value: id,
      })),
    },
    {
      title: 'Profesión',
      dataIndex: 'professionId',
      displayOnTable: false,
      type: 'select',
      options: professions.map(({ id, name }) => ({
        label: name,
        value: id,
      })),
    },
    {
      title: 'Teléfono',
      dataIndex: 'phoneNumber',
      displayOnTable: false,
      type: 'string',
    },
    {
      title: 'Correo Electrónico',
      dataIndex: 'email',
      displayOnTable: false,
      type: 'string',
    },
    {
      title: 'Dirección',
      dataIndex: 'address',
      displayOnTable: false,
      type: 'string',
    },
    {
      title: 'RUT',
      dataIndex: 'rut',
      displayOnTable: false,
      type: 'string',
    },
    {
      title: 'Sexo',
      dataIndex: 'sex',
      type: 'select',
      options: [
        { label: 'Masculino', value: 'M' },
        { label: 'Femenino', value: 'F' },
      ],
      filter: true,
      render: val => {
        return val === 'M' ? 'Masculino' : 'Femenino'
      },
    },
    {
      title: 'Fecha de Nacimiento',
      dataIndex: 'dateOfBirth',
      type: 'date',
      render: val => {
        return dayjs(val).format('D [de] MMMM [del] YYYY')
      },
    },
    {
      title: 'Ver fichas',
      dataIndex: 'id',
      type: 'string',
      render: record => {
        return (
          <Button
            onClick={() => navigate(`/patients/${record}`)}
            icon={<EyeOutlined />}
          >
            Ver fichas
          </Button>
        )
      },
    },
    {
      title: 'Referido por',
      dataIndex: 'referedBy',
      type: 'string',
      displayOnTable: false,
    },
  ]

  return (
    <CRUDTableComponent
      entityName={{
        singular: 'Paciente',
        plural: 'Pacientes',
      }}
      tableColumns={tableColumns}
      entityUrl="/patients"
    />
  )
}

export default PatientsTable
