const findErrorByKey = ({ formErrors, key }) => {
  const error = formErrors.find(err => err.field === key)
  if (error) {
    return {
      validateStatus: 'error',
      help: error.message,
    }
  }
  return {}
}

export default findErrorByKey
