import { Button, Card, Checkbox, Form, Row, Col, notification } from 'antd'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import apiClient, { handleApiError } from '../../../services/apiClient'

const DietaryHabitsInformation = ({ dietaryHabits }) => {
  const [loading, setLoading] = useState(false)
  const { id } = useParams()

  const [form] = Form.useForm()

  useEffect(() => {
    const normalizedDietaryHabits = {
      sweet: !!dietaryHabits?.sweet,
      salty: !!dietaryHabits?.salty,
      fastFood: !!dietaryHabits?.fastFood,
      ansiousEater: !!dietaryHabits?.ansiousEater,
      nightEater: !!dietaryHabits?.nightEater,
      goodAppetiteEater: !!dietaryHabits?.goodAppetiteEater,
    }
    form.setFieldsValue(normalizedDietaryHabits)
  }, [dietaryHabits, form])

  const onFinish = async values => {
    try {
      setLoading(true)
      await apiClient.post(`/patients/${id}/dietary-habits`, values)
      notification.success({
        message: 'Hábitos alimenticios actualizados',
        duration: 2,
      })
    } catch (error) {
      handleApiError({ error })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Card style={{ marginTop: '6vh' }}>
      <Form
        layout="horizontal"
        initialValues={dietaryHabits}
        onFinish={onFinish}
        form={form}
      >
        <h1>Hábitos Alimenticios</h1>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item label="Dulces" name="sweet" valuePropName="checked">
              <Checkbox />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Salados" name="salty" valuePropName="checked">
              <Checkbox />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Comida rápida"
              name="fastFood"
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="Comedor ansioso"
              name="ansiousEater"
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Comedor nocturno"
              name="nightEater"
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Buen apetito"
              name="goodAppetiteEater"
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Form.Item>
              <Button type="primary" loading={loading} htmlType="submit" block>
                Guardar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  )
}

export default DietaryHabitsInformation
