import { createBrowserRouter } from 'react-router-dom'

import BodyFatRecommendationsForm from './pages/BodyFatRecommendations/BodyFatRecommendationsForm'
import BodyFatRecommendationsTable from './pages/BodyFatRecommendations/BodyFatRecommendationsTable'
import ClinicalRecordsCreate from './pages/ClinicalRecords/ClinicalRecordsCreate'
import ClinicalRecordsForm from './pages/ClinicalRecords/ClinicalRecordsForm'
import CommunesForm from './pages/Communes/CommunesForm'
import CommunesTable from './pages/Communes/CommunesTable'
import ConsultationReasonsForm from './pages/ConsultationReasons/ConsultationReasonsForm'
import ConsultationReasonsTable from './pages/ConsultationReasons/ConsultationReasonsTable'
import DiseasesForm from './pages/Diseases/DiseasesForm'
import DiseasesTable from './pages/Diseases/DiseasesTable'
import HealthInsurancesForm from './pages/HealthInsurances/HealthInsurancesForm'
import HealthInsurancesTable from './pages/HealthInsurances/HealthInsurancesTable'
import LabExamsForm from './pages/LabExams/LabExamsForm'
import LabExamsTable from './pages/LabExams/LabExamsTable'
import MedicationsForm from './pages/Medications/MedicationsForm'
import MedicationsTable from './pages/Medications/MedicationsTable'
import PatientsDetail from './pages/Patients/PatientsDetail'
import PatientsForm from './pages/Patients/PatientsForm'
import PatientsTable from './pages/Patients/PatientsTable'
import PhysicalActivityLevelsForm from './pages/PhysicalActivityLevels/PhysicalActivityLevelsForm'
import PhysicalActivityLevelsTable from './pages/PhysicalActivityLevels/PhysicalActivityLevelsTable'
import PhysicalExamsForm from './pages/PhysicalExams/PhysicalExamsForm'
import PhysicalExamsTable from './pages/PhysicalExams/PhysicalExamsTable'
import ProfessionsTable from './pages/Professions/ProfessionsTable'
import ProffessionsForm from './pages/Professions/ProffessionsForm'
import Root from './pages/Root'
import SignIn from './pages/SignIn'
import SurgeriesForm from './pages/Surgeries/SurgeriesForm'
import SurgeriesTable from './pages/Surgeries/SurgeriesTable'

const router = createBrowserRouter([
  {
    path: '/sign-in',
    element: <SignIn />,
  },
  {
    path: '/',
    element: <Root />,
    children: [
      {
        path: '/patients',
        element: <PatientsTable />,
      },
      {
        path: '/patients/create',
        element: <PatientsForm />,
      },
      {
        path: '/clinical-records/:id',
        element: <ClinicalRecordsForm />,
      },
      {
        path: '/patients/:id/create',
        element: <ClinicalRecordsCreate />,
      },
      {
        path: '/patients/:id',
        element: <PatientsDetail />,
      },
      {
        path: '/professions',
        element: <ProfessionsTable />,
      },
      {
        path: '/professions/create',
        element: <ProffessionsForm />,
      },
      {
        path: '/communes',
        element: <CommunesTable />,
      },
      {
        path: '/communes/create',
        element: <CommunesForm />,
      },
      {
        path: '/physical-activity-levels',
        element: <PhysicalActivityLevelsTable />,
      },
      {
        path: '/physical-activity-levels/create',
        element: <PhysicalActivityLevelsForm />,
      },
      {
        path: '/health-insurances',
        element: <HealthInsurancesTable />,
      },
      {
        path: '/health-insurances/create',
        element: <HealthInsurancesForm />,
      },
      {
        path: '/consultation-reasons',
        element: <ConsultationReasonsTable />,
      },
      {
        path: '/consultation-reasons/create',
        element: <ConsultationReasonsForm />,
      },
      {
        path: '/medications',
        element: <MedicationsTable />,
      },
      {
        path: '/medications/create',
        element: <MedicationsForm />,
      },
      {
        path: '/surgeries',
        element: <SurgeriesTable />,
      },
      {
        path: '/surgeries/create',
        element: <SurgeriesForm />,
      },
      {
        path: '/body-fat-recommendations',
        element: <BodyFatRecommendationsTable />,
      },
      {
        path: '/body-fat-recommendations/create',
        element: <BodyFatRecommendationsForm />,
      },
      {
        path: '/physical-exams',
        element: <PhysicalExamsTable />,
      },
      {
        path: '/physical-exams/create',
        element: <PhysicalExamsForm />,
      },
      {
        path: '/lab-exams',
        element: <LabExamsTable />,
      },
      {
        path: '/lab-exams/create',
        element: <LabExamsForm />,
      },
      {
        path: '/diseases',
        element: <DiseasesTable />,
      },
      {
        path: '/diseases/create',
        element: <DiseasesForm />,
      },
    ],
  },
])

export default router
