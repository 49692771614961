import { Form, Input, Button, Select, Row, Col } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'

const FormListSection = ({
  name,
  label,
  options,
  itemLabel,
  typeFieldName,
  descriptionFieldName,
}) => (
  <>
    <h1>{label}</h1>
    <Form.List name={name}>
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }) => (
            <Row key={key} gutter={16} align="middle">
              <Col span={10}>
                <Form.Item
                  {...restField}
                  name={[name, typeFieldName]}
                  label={itemLabel}
                  rules={[
                    {
                      required: true,
                      message: `Se requiere un ${itemLabel.toLowerCase()}`,
                    },
                  ]}
                >
                  <Select
                    placeholder={`Selecciona un ${itemLabel.toLowerCase()}`}
                    options={options}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  {...restField}
                  name={[name, descriptionFieldName]}
                  label="Descripción"
                  rules={[
                    { required: true, message: 'Se requiere una descripción' },
                  ]}
                >
                  <Input placeholder="Descripción" />
                </Form.Item>
              </Col>
              <Col span={2}>
                <MinusCircleOutlined onClick={() => remove(name)} />
              </Col>
            </Row>
          ))}
          <Form.Item>
            <Button
              type="dashed"
              onClick={() => add()}
              icon={<PlusOutlined />}
              style={{ width: '100%' }}
            >
              Agregar {itemLabel.toLowerCase()}
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  </>
)

export default FormListSection
