import { Divider, Form, Input, Select } from 'antd'

const FirstFormSection = ({
  consultationReasons,
  diseases,
  findErrorByKey,
}) => {
  return (
    <>
      <Form.Item
        key="consultationReasonId"
        name="consultationReasonId"
        label="Motivo de consulta"
        {...findErrorByKey('consultationReasonId')}
      >
        <Select options={consultationReasons} />
      </Form.Item>
      <Form.Item
        key="consultationReasonComment"
        name="consultationReasonComment"
        label="Descripción"
        {...findErrorByKey('consultationReasonComment')}
      >
        <Input.TextArea
          placeholder="Describa el motivo de la consulta"
          autoSize={{ minRows: 3, maxRows: 5 }}
        />
      </Form.Item>
      <Divider />
      <Form.Item
        key="diseaseId"
        name="diseaseId"
        label="Enfermedad"
        {...findErrorByKey('diseaseId')}
      >
        <Select options={diseases} />
      </Form.Item>
      <Form.Item
        key="diseaseComment"
        name="diseaseComment"
        label="Descripción"
        {...findErrorByKey('diseaseComment')}
      >
        <Input.TextArea
          placeholder="Describa la enfermedad"
          autoSize={{ minRows: 3, maxRows: 5 }}
        />
      </Form.Item>
    </>
  )
}

export default FirstFormSection
