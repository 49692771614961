import { Card, Flex, Form, Button, notification } from 'antd'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import apiClient, { handleApiError } from '../../services/apiClient.js'
import findErrorByKey from './findErrorByKey.js'
import getFieldByType from './getFieldByType.js'

const CRUDFormComponent = ({ entityName, fields, entityUrl }) => {
  const [loading, setLoading] = useState(false)
  const [formErrors, setFormErrors] = useState([])
  const [form] = Form.useForm()

  const navigate = useNavigate()

  useEffect(() => {
    if (!entityUrl) {
      throw new Error('entityUrl is required')
    }
  }, [entityUrl])

  useEffect(() => {
    const formInitialValues = {}
    fields.forEach(field => {
      if (field.value) {
        formInitialValues[field.key] = field.value
      }
    })
    form.setFieldsValue(formInitialValues)
  }, [fields, form])

  const onFinish = async formValues => {
    setFormErrors([])
    setLoading(true)

    try {
      await apiClient.post(entityUrl, formValues)

      notification.success({
        message: `¡Registro creado!`,
        description: `${entityName.singular}`,
        duration: 2,
        onClose: () => {
          navigate(-1)
        },
      })
    } catch (error) {
      handleApiError({
        error,
        setErrors: setFormErrors,
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Card>
      <Flex align="center" justify="space-between">
        <h1>Crear {entityName.singular}</h1>
        <Button type="default" onClick={() => navigate(-1)}>
          Volver
        </Button>
      </Flex>
      <Form layout="vertical" onFinish={onFinish} form={form}>
        {fields.map(field => (
          <Form.Item
            key={field.key}
            name={field.key}
            label={field.label}
            required={field.required || false}
            {...findErrorByKey({ formErrors, key: field.key })}
          >
            {getFieldByType({
              type: field.type,
              disabled: field.disabled || false,
              options: field.options || [],
            })}
          </Form.Item>
        ))}
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading} block>
            Guardar {entityName.singular}
          </Button>
        </Form.Item>
      </Form>
    </Card>
  )
}

export default CRUDFormComponent
