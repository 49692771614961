import { useEffect, useState } from 'react'
import { DatePicker, Form, Input, Modal, Select, notification } from 'antd'
import dayjs from 'dayjs'

import apiClient, { handleApiError } from '../../services/apiClient'

const ModalEdit = ({
  entityName,
  entityUrl,
  fetchEntityList,
  fields,
  isModalOpen,
  selectedRecord,
  setIsModalOpen,
}) => {
  const [isModalLoading, setIsModalLoading] = useState(false)
  const [formFields, setFormFields] = useState([])
  const [formErrors, setFormErrors] = useState([])
  const [record, setRecord] = useState({})
  const [form] = Form.useForm()

  useEffect(() => {
    if (!selectedRecord) return

    const record = { ...selectedRecord }
    const dateFields = fields.filter(({ type }) => type === 'date')

    for (const dateField of dateFields) {
      if (record[dateField.dataIndex]) {
        record[dateField.dataIndex] = dayjs(record[dateField.dataIndex])
      }
    }

    setRecord(record)
  }, [selectedRecord, fields])

  useEffect(() => {
    setFormFields([
      ...fields
        .filter(
          ({ dataIndex }) => dataIndex !== 'id' && dataIndex !== 'createdAt'
        )
        .map(({ dataIndex, title, type, options }) => ({
          key: dataIndex,
          label: title,
          type,
          options,
        })),
    ])
  }, [fields])

  useEffect(() => {
    if (isModalOpen) {
      form.setFieldsValue(record)
    }
  }, [record, isModalOpen, form])

  const handleOk = async () => {
    setIsModalLoading(true)
    try {
      const url = entityUrl.split('?')[0]
      await apiClient.put(`${url}/${selectedRecord.id}`, form.getFieldsValue())
      notification.success({
        message: `${entityName.singular} editado correctamente`,
        duration: 2,
      })
      await fetchEntityList(entityUrl)
      setIsModalLoading(false)
      setIsModalOpen(false)
    } catch (error) {
      handleApiError({ error, setErrors: setFormErrors })

      setIsModalLoading(false)
    }
  }

  const findErrorByKey = key => {
    const error = formErrors.find(err => err.field === key)
    if (error) {
      return {
        validateStatus: 'error',
        help: error.message,
      }
    }
    return {}
  }

  const getFieldByType = ({ type, options }) => {
    if (type === 'date') {
      return <DatePicker style={{ width: '100%' }} />
    }
    if (type === 'number') {
      return <Input type="number" />
    }

    if (type === 'select') {
      return (
        <Select
          options={options.map(({ label, value }) => ({
            label,
            value,
          }))}
        />
      )
    }

    return type === 'string' ? <Input /> : <Input />
  }

  return (
    <Modal
      title={`Editar ${entityName.singular} - ID: ${selectedRecord?.id}`}
      open={isModalOpen}
      confirmLoading={isModalLoading}
      onOk={handleOk}
      onCancel={() => {
        setIsModalOpen(false)
      }}
      okText="Editar"
      cancelText="Cancelar"
    >
      <Form layout="vertical" form={form}>
        {formFields.map(field => (
          <Form.Item
            key={field.key}
            label={field.label}
            name={field.key}
            {...findErrorByKey(field.key)}
          >
            {getFieldByType({ type: field.type, options: field.options })}
          </Form.Item>
        ))}
      </Form>
    </Modal>
  )
}

export default ModalEdit
