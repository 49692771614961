import { Outlet } from 'react-router-dom'
import { Layout } from 'antd'

import SideMenu from './SideMenu'
import { useEffect } from 'react'

const { Content, Header } = Layout

const Root = () => {
  useEffect(() => {
    if (window.location.pathname === '/') {
      window.location.href = '/patients'
    }
  }, [])

  return (
    <Layout
      style={{
        minHeight: '100vh',
      }}
    >
      <Layout>
        <Header style={{ padding: 0, backgroundColor: '#fff' }} />
        <SideMenu />
        <Content
          style={{
            marginLeft: '260px',
            padding: 16
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  )
}

export default Root
