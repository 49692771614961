import React from 'react'

import FormListSection from './FormListSection'

const ThirdFormSection = ({ labExams, medications, physicalExams }) => {
  return (
    <>
      <FormListSection
        name="physicalExams"
        label="Exámenes físicos"
        options={physicalExams}
        itemLabel="Examen"
        typeFieldName="physicalExamId"
        descriptionFieldName="description"
      />
      <FormListSection
        name="labExams"
        label="Exámenes de laboratorio"
        options={labExams}
        itemLabel="Examen"
        typeFieldName="labExamId"
        descriptionFieldName="description"
      />
      <FormListSection
        name="medications"
        label="Tratamientos"
        options={medications}
        itemLabel="Tratamiento"
        typeFieldName="medicationId"
        descriptionFieldName="description"
      />
    </>
  )
}

export default ThirdFormSection
