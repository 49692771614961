import { Button, Flex } from 'antd'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'

const normalizeString = str => {
  if (!str) return ''

  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
}

const generateColumns = (
  tableColumns,
  onDelete,
  onEdit,
  tableColumnsActions,
  entityList
) => {
  if (!tableColumns) {
    throw new Error('tableColumns is required for generateColumns function')
  }
  const result = [
    ...tableColumns
      .filter(el => el.displayOnTable !== false)
      .map(({ title, dataIndex, render, sorter, filter }) => {
        return {
          title,
          dataIndex,
          key: dataIndex,
          ...(filter
            ? {
                filterSearch: (input, record) => {
                  const recordValue = record.value
                  return normalizeString(recordValue).includes(
                    normalizeString(input)
                  )
                },
                filters: [
                  ...new Set(entityList.map(entity => entity[dataIndex])),
                ]
                  .sort()
                  .map(value => ({
                    text: value,
                    value: normalizeString(value), // HERE
                  })),
                onFilter: (value, record) => {
                  return normalizeString(record[dataIndex]) === value
                },
              }
            : undefined),
          ...(render ? { render } : undefined),
          ...(sorter ? { sorter } : undefined),
        }
      }),
  ]

  return result.concat({
    title: 'Acciones',
    key: 'actions',
    dataIndex: 'actions',
    render: (_text, record) => (
      <Flex align="center" justify="space-around" style={{ width: '100px' }}>
        {tableColumnsActions.delete && (
          <Button
            danger
            type="primary"
            icon={<DeleteOutlined />}
            onClick={() => onDelete(record)}
          />
        )}
        {tableColumnsActions.edit && (
          <Button
            type="primary"
            icon={<EditOutlined />}
            onClick={() => onEdit(record)}
          />
        )}
      </Flex>
    ),
  })
}

export default generateColumns
