const calculateTotalBodyWater = (weight, height, age) => {
  if (!weight || !height || !age) return null

  let totalBodyWater = 2.447 - 0.09156 * age + 0.1074 * height + 0.3362 * weight

  totalBodyWater = totalBodyWater.toFixed(2)

  return totalBodyWater
}

export default calculateTotalBodyWater
