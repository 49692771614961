import CRUDFormComponent from '../../components/CRUDFormComponent'

const ConsultationReasonsForm = () => {
  const fields = [{ key: 'reason', label: 'Motivo', type: 'string' }]

  return (
    <>
      <CRUDFormComponent
        entityName={{ singular: 'Motivo de consulta' }}
        fields={fields}
        entityUrl="/consultation-reasons"
      />
    </>
  )
}

export default ConsultationReasonsForm
