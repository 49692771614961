import CRUDTableComponent from '../../components/CRUDTableComponent'

const LabExamsTable = () => {
  const tableColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
      type: 'string',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
  ]

  return (
    <CRUDTableComponent
      entityName={{
        singular: 'Examen de laboratorio',
        plural: 'Examenes de laboratorio',
      }}
      tableColumns={tableColumns}
      entityUrl="/lab-exams?sortBy=name"
    />
  )
}

export default LabExamsTable
