import { Card, Input, Form, Button, notification } from 'antd'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import apiClient, { handleApiError } from '../../../services/apiClient'

const { TextArea } = Input

const FamilyBackground = ({ familyBackground }) => {
  const [loading, setLoading] = useState(false)

  const { id } = useParams()
  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldValue('familyBackground', familyBackground)
  }, [familyBackground, form])

  const onFinish = async values => {
    try {
      setLoading(true)
      await apiClient.post(`/patients/${id}/family-backgrounds`, values)
      notification.success({
        message: 'Antecedentes familiares actualizados',
        duration: 2,
      })
    } catch (error) {
      handleApiError({ error })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Card style={{ marginTop: '6vh' }}>
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <h1>Antecedentes familiares</h1>
        <Form.Item name="familyBackground" label="Antecedentes familiares">
          <TextArea
            placeholder="Antecedentes familiares"
            autoSize={{ minRows: 5, maxRows: 10 }}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading} block>
            Guardar
          </Button>
        </Form.Item>
      </Form>
    </Card>
  )
}

export default FamilyBackground
