import { useEffect, useState } from 'react'

import apiClient, { handleApiError } from '../../services/apiClient'
import CRUDFormComponent from '../../components/CRUDFormComponent'

const PatientsForm = () => {
  const [communes, setCommunes] = useState([])
  const [healthInsurances, setHealthInsurances] = useState([])
  const [professions, setProfessions] = useState([])
  const [PhysicalActivityLevels, setPhysicalActivityLevels] = useState([])

  const fetchData = async () => {
    try {
      const [communes, healthInsurances, physicalActivityLevels, professions] =
        await Promise.all([
          apiClient.get('/communes?sortBy=name'),
          apiClient.get('/health-insurances?sortBy=name'),
          apiClient.get('/physical-activity-levels?sortBy=level'),
          apiClient.get('/professions?sortBy=name'),
        ])

      setCommunes(communes.data)
      setHealthInsurances(healthInsurances.data)
      setProfessions(professions.data)
      setPhysicalActivityLevels(physicalActivityLevels.data)
    } catch (error) {
      handleApiError({ error })
    }
  }
  useEffect(() => {
    fetchData()
  }, [])

  const fields = [
    {
      key: 'firstName',
      label: 'Nombre',
      type: 'string',
      required: true,
    },
    {
      key: 'secondName',
      label: 'Segundo nombre',
      type: 'string',
    },
    {
      key: 'firstLastName',
      label: 'Apellido',
      type: 'string',
      required: true,
    },
    {
      key: 'secondLastName',
      label: 'Apellido materno',
      type: 'string',
      required: false,
    },
    {
      key: 'dateOfBirth',
      label: 'Fecha de nacimiento',
      type: 'date',
      required: true,
    },
    {
      key: 'sex',
      label: 'Sexo',
      type: 'select',
      required: true,
      options: [
        { label: 'Masculino', value: 'M' },
        { label: 'Femenino', value: 'F' },
      ],
    },
    {
      key: 'phoneNumber',
      label: 'Teléfono',
      type: 'string',
    },
    {
      key: 'email',
      label: 'Correo electrónico',
      type: 'string',
    },
    {
      key: 'address',
      label: 'Dirección',
      type: 'string',
    },
    {
      key: 'communeId',
      label: 'Comuna',
      type: 'select',
      options: communes.map(commune => ({
        label: commune.name,
        value: commune.id,
      })),
      required: true,
    },
    {
      key: 'healthInsuranceId',
      label: 'Previsión',
      type: 'select',
      options: healthInsurances.map(healthInsurance => ({
        label: healthInsurance.name,
        value: healthInsurance.id,
      })),
      required: true,
    },
    {
      key: 'professionId',
      label: 'Profesión',
      type: 'select',
      options: professions.map(profession => ({
        label: profession.name,
        value: profession.id,
      })),
      required: true,
    },
    {
      key: 'physicalActivityLevelId',
      label: 'Nivel de actividad física',
      type: 'select',
      options: PhysicalActivityLevels.map(physicalActivityLevel => ({
        label: physicalActivityLevel.level,
        value: physicalActivityLevel.id,
      })),
      required: true,
    },
    {
      key: 'referedBy',
      label: 'Referido por',
      type: 'string',
    },
    {
      key: 'rut',
      label: 'RUT',
      type: 'string',
    },
  ]

  return (
    <>
      <CRUDFormComponent
        entityName={{ singular: 'Paciente' }}
        fields={fields}
        entityUrl="/patients"
      />
    </>
  )
}

export default PatientsForm
