import { useState } from 'react'
import { Button, Card, Flex, Form, Input, Layout, notification } from 'antd'
import apiClient from '../../services/apiClient'

const SignIn = () => {
  const [loading, setLoading] = useState(false)

  const onFinish = async values => {
    setLoading(true)
    try {
      const { data } = await apiClient.post('/auth/sign-in', values)
      const { token } = data
      localStorage.setItem('authToken', token)
      window.location.href = '/'
    } catch (error) {
      setLoading(false)
      notification.error({
        message: 'Error',
        description: error.message,
        duration: 10,
      })
    }
  }

  return (
    <Layout
      style={{
        minHeight: '100vh',
      }}
    >
      <Card
        style={{
          width: '30vw',
          margin: 'auto',
          marginTop: '30vh',
          borderRadius: 10,
          boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
        }}
      >
        <Form layout="vertical" onFinish={onFinish}>
          <Flex justify="center" style={{ marginBottom: 20 }}>
            <h1>Iniciar sesión</h1>
          </Flex>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: 'Por favor ingrese su email' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Contraseña"
            name="password"
            rules={[
              { required: true, message: 'Por favor ingrese su contraseña' },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item style={{ marginTop: 30 }}>
            <Button type="primary" htmlType="submit" loading={loading} block>
              Iniciar sesión
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Layout>
  )
}

export default SignIn
