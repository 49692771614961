import VitalSignsComponent from './VitalSignsComponent'
import AnthropometryComponent from './AnthropometryComponent'
import GoalComponent from './GoalsComponent'

const SecondFormSection = ({ findErrorByKey }) => {
  return (
    <>
      <VitalSignsComponent findErrorByKey={findErrorByKey} />
      <AnthropometryComponent findErrorByKey={findErrorByKey} />
      <GoalComponent findErrorByKey={findErrorByKey} />
    </>
  )
}

export default SecondFormSection
