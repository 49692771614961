import { useParams } from 'react-router-dom'

import CRUDFormComponent from '../../../components/CRUDFormComponent'

const ClinicalRecordsCreate = () => {
  const { id: patientId } = useParams()

  const fields = [
    {
      key: 'patientId',
      label: 'ID del paciente',
      type: 'string',
      disabled: true,
      value: patientId,
    },
  ]

  return (
    <>
      <CRUDFormComponent
        entityName={{ singular: 'Registro Clinico' }}
        fields={fields}
        entityUrl="/clinical-records"
      />
    </>
  )
}

export default ClinicalRecordsCreate
