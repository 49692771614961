import { Card, Flex, Form, InputNumber, Input } from 'antd'

const VitalSignsComponent = ({ findErrorByKey }) => {
  return (
    <Card
      style={{
        marginBottom: '1rem',
        borderRadius: '10px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
      }}
    >
      <b>Signos vitales</b>
      <Flex direction="row" justify="space-between" gap="large">
        <Form.Item
          name="heartbeat"
          label="Pulso"
          rules={[{ required: true, message: 'Por favor, ingresa el pulso' }]}
          style={{ flex: 1 }}
          {...findErrorByKey('heartbeat')}
        >
          <InputNumber style={{ width: '100%' }} addonAfter="ppm" />
        </Form.Item>

        <Form.Item
          name="bloodPressure"
          label="Presión Arterial (PA)"
          rules={[
            {
              required: true,
              message: 'Por favor, ingresa la presión arterial',
            },
          ]}
          style={{ flex: 1 }}
          {...findErrorByKey('bloodPressure')}
        >
          <Input style={{ width: '100%' }} addonAfter="mmHg" />
        </Form.Item>

        <Form.Item
          name="breathingFrequency"
          label="Frecuencia Respiratoria (FR)"
          rules={[
            {
              required: true,
              message: 'Por favor, ingresa la frecuencia respiratoria',
            },
          ]}
          {...findErrorByKey('breathingFrequency')}
          style={{ flex: 1 }}
        >
          <InputNumber style={{ width: '100%' }} addonAfter="rpm" />
        </Form.Item>

        <Form.Item
          name="reactance"
          label="Reactancia"
          style={{ flex: 1 }}  
          {...findErrorByKey('reactance')}
        >
          <InputNumber style={{ width: '100%' }} addonAfter="Ohm" />
        </Form.Item>
      </Flex>
    </Card>
  )
}

export default VitalSignsComponent
