import apiClient from '../../../services/apiClient'

const downloadPDF = async path => {
  try {
    const response = await apiClient.post(
      path,
      {},
      {
        responseType: 'arraybuffer',
      }
    )

    const blob = new Blob([response.data], { type: 'application/pdf' })
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `reporte_consulta.pdf`)
    document.body.appendChild(link)
    link.click()
    link.remove()
  } catch (error) {
    console.error('Error downloading PDF', error)
  }
}

export default downloadPDF
