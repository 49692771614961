import axios from 'axios'
import { notification } from 'antd'

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
})

apiClient.interceptors.request.use(config => {
  const token = localStorage.getItem('authToken')
  if (token) {
    config.headers.authorization = `Bearer ${token}`
  }
  return config
})

apiClient.interceptors.response.use(
  response => response,
  error => {
    console.log('Error response', error)
    if (error.response) {
      const { status } = error.response

      if (status === 401) {
        localStorage.removeItem('authToken')
        if (window.location.pathname !== '/sign-in') {
          window.location.href = '/sign-in'
        }
        error.handled = true
        return Promise.reject(error)
      }
    } else if (error.request) {
      notification.error({
        message: 'Error de conexión',
        description:
          'No se pudo conectar con el servidor. Por favor, inténtelo de nuevo más tarde.',
      })
      error.handled = true
      return Promise.reject(error)
    }

    console.log(error)
    return Promise.reject(error)
  }
)

export const handleApiError = ({ error, setErrors = () => {} }) => {
  console.log('Error: ', error)

  // Verifica si el error ya ha sido gestionado por el interceptor
  if (error.handled) {
    return
  }

  if (error.response) {
    const { data, status } = error.response
    console.error('API error', data)

    if (status === 409) {
      notification.error({
        message: 'Error',
        description: data.errors[0].message,
      })
    } else if (data.errors) {
      const { errors } = data
      setErrors(errors)
      notification.error({
        message: 'Error en el formulario',
        description: errors.map(e => e.message).join(', '),
      })
    } else {
      console.error('Error without response', error)
      notification.error({
        message: 'Error',
        description: 'Ocurrió un error al intentar crear el registro.',
      })
    }
  }
}
export default apiClient
