import CRUDTableComponent from '../../components/CRUDTableComponent'

const PhysicalExamsTable = () => {
  const tableColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
      type: 'string',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
  ]

  return (
    <CRUDTableComponent
      entityName={{ singular: 'Examen físico', plural: 'Examenes físicos' }}
      tableColumns={tableColumns}
      entityUrl="/physical-exams?sortBy=name"
    />
  )
}

export default PhysicalExamsTable
