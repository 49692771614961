import { Card, Menu } from 'antd'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  MedicineBoxOutlined,
  ExperimentOutlined,
  SolutionOutlined,
} from '@ant-design/icons'

import apiClient from '../../../services/apiClient'
import ClinicalRecordsForm from './ClinicalRecordsForm'

const ClinicalRecords = () => {
  const [consultationReasons, setConsultationReasons] = useState([])
  const [diseases, setDiseases] = useState([])
  const [labExams, setLabExams] = useState([])
  const [physicalExams, setPhysicalExams] = useState([])
  const [medications, setMedications] = useState([])
  const [patient, setPatient] = useState()
  const [clinicalRecord, setClinicalRecord] = useState()
  const [current, setCurrent] = useState('1')

  const { id } = useParams()

  const fetchInformation = async id => {
    try {
      const [
        consultationReasons,
        diseases,
        clinicalRecord,
        medications,
        labExams,
        physicalExams,
      ] = await Promise.all([
        apiClient.get('/consultation-reasons'),
        apiClient.get('/diseases'),
        apiClient.get(`/clinical-records/${id}`),
        apiClient.get('/medications'),
        apiClient.get('/lab-exams'),
        apiClient.get('/physical-exams'),
      ])

      // Can't be called earlier because clinicalRecord is needed
      const patient = await apiClient.get(
        `/patients/${clinicalRecord.data.patientId}`
      )
      setPatient(patient.data)
      setClinicalRecord(clinicalRecord.data)
      setConsultationReasons(
        consultationReasons.data.map(item => ({
          value: item.id,
          label: item.reason,
        }))
      )
      setDiseases(
        diseases.data.map(item => ({
          value: item.id,
          label: item.name,
        }))
      )
      setLabExams(
        labExams.data.map(item => ({
          value: item.id,
          label: item.name,
        }))
      )
      setPhysicalExams(
        physicalExams.data.map(item => ({
          value: item.id,
          label: item.name,
        }))
      )
      setMedications(
        medications.data.map(item => ({
          value: item.id,
          label: item.name,
        }))
      )
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchInformation(id)
  }, [id])

  const onClick = e => {
    setCurrent(e.key)
  }

  const items = [
    {
      label: 'Motivos / Enfermedad',
      key: '1',
      icon: <MedicineBoxOutlined />,
    },
    {
      label: 'Examenes',
      key: '2',
      icon: <ExperimentOutlined />,
    },
    {
      label: 'E. Clínico / E. de laboratorio / Diag. / Trata.',
      key: '3',
      icon: <SolutionOutlined />,
    },
  ]

  return (
    <>
      <Card>
        <Menu
          onClick={onClick}
          selectedKeys={[current]}
          mode="horizontal"
          items={items}
        />
        <ClinicalRecordsForm
          current={current}
          clinicalRecord={clinicalRecord}
          consultationReasons={consultationReasons}
          diseases={diseases}
          labExams={labExams}
          physicalExams={physicalExams}
          medications={medications}
          patient={patient}
        />
      </Card>
    </>
  )
}

export default ClinicalRecords
