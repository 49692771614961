import CRUDTableComponent from '../../components/CRUDTableComponent'

const MedicationsTable = () => {
  const tableColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
  ]

  return (
    <CRUDTableComponent
      entityName={{ singular: 'Medicamento', plural: 'Medicamentos' }}
      tableColumns={tableColumns}
      entityUrl="/medications?sortBy=name"
    />
  )
}

export default MedicationsTable
