import { Modal, notification } from 'antd'
import { useState } from 'react'

import apiClient, { handleApiError } from '../../services/apiClient'

const ModalDeleteConfirmation = ({
  entityName,
  entityUrl,
  selectedRecordId,
  isModalOpen,
  setIsModalOpen,
  fetchEntityList,
}) => {
  const [isModalLoading, setIsModalLoading] = useState(false)

  const handleDelete = async () => {
    setIsModalLoading(true)

    try {
      const url = entityUrl.split('?')[0]
      await apiClient.delete(`${url}/${selectedRecordId}`)
      notification.success({
        message: `${entityName.singular} eliminado exitosamente`,
        duration: 2,
      })
      await fetchEntityList(entityUrl)
      setIsModalOpen(false)
    } catch (error) {
      handleApiError({ error })
    } finally {
      setIsModalLoading(false)
    }
  }

  return (
    <Modal
      title={`Eliminar ${entityName.singular} - ID: ${selectedRecordId}`}
      open={isModalOpen}
      onOk={handleDelete}
      onCancel={() => {
        setIsModalOpen(false)
      }}
      confirmLoading={isModalLoading}
      okType="danger"
      okText="Eliminar"
      cancelText="Cancelar"
    >
      <p>¿Estás seguro de que deseas eliminar elemento?</p>
    </Modal>
  )
}

export default ModalDeleteConfirmation
