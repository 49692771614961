import CRUDTableComponent from '../../components/CRUDTableComponent'

const PhysicalActivityLevelsTable = () => {
  const tableColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Nivel',
      dataIndex: 'level',
      type: 'string',
      sorter: (a, b) => a.level.localeCompare(b.level),
    },
    {
      title: 'Calorías por día',
      dataIndex: 'caloriesPerDay',
      type: 'number',
      sorter: (a, b) => a.caloriesPerDay - b.caloriesPerDay,
    },
  ]

  return (
    <CRUDTableComponent
      entityName={{
        singular: 'Nivel de actividad física',
        plural: 'Niveles de actividad física',
      }}
      tableColumns={tableColumns}
      entityUrl="/physical-activity-levels?sortBy=level"
    />
  )
}

export default PhysicalActivityLevelsTable
