import CRUDFormComponent from '../../components/CRUDFormComponent'

const BodyFatRecommendationsForm = () => {
  const fields = [
    { key: 'ageFrom', label: 'Edad desde', type: 'number' },
    { key: 'ageTo', label: 'Edad hasta', type: 'number' },
    {
      key: 'maleBodyFat',
      label: 'Grasa corporal recomendada (hombres)',
      type: 'number',
    },
    {
      key: 'femaleBodyFat',
      label: 'Grasa corporal recomendada (mujeres)',
      type: 'number',
    },
  ]

  return (
    <>
      <CRUDFormComponent
        entityName={{
          singular: 'Registro de grasa corporal recomendada por edad',
        }}
        fields={fields}
        entityUrl="/recommended-body-fat"
      />
    </>
  )
}

export default BodyFatRecommendationsForm
