import { Card, Flex, Form, InputNumber } from 'antd'

const AnthropometryComponent = ({ findErrorByKey }) => {
  return (
    <Card
      style={{
        marginBottom: '1rem',
        borderRadius: '10px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
      }}
    >
      <b>Antropometría</b>
      <Flex direction="row" justify="space-between" gap="large">
        <Form.Item
          name="weight"
          label="Peso"
          rules={[{ required: true, message: 'Por favor, ingresa el peso' }]}
          style={{ flex: 1 }}
          {...findErrorByKey('weight')}
        >
          <InputNumber style={{ width: '100%' }} addonAfter="kg" />
        </Form.Item>
        <Form.Item
          name="height"
          label="Talla"
          rules={[{ required: true }]}
          style={{ flex: 1 }}
          {...findErrorByKey('height')}
        >
          <InputNumber style={{ width: '100%' }} addonAfter="m" />
        </Form.Item>
        <Form.Item
          label="IMC"
          name="IMC"
          rules={[{ required: false }]}
          style={{ flex: 1 }}
        >
          <InputNumber style={{ width: '100%' }} addonAfter="kg/m²" disabled />
        </Form.Item>
      </Flex>
      <Flex direction="row" justify="space-between" gap="large">
        <Form.Item
          name="waistCircumference"
          label="Perímetro cintura F(> 88) M(> 102)"
          rules={[{ required: true }]}
          style={{ flex: 1 }}
          {...findErrorByKey('waistCircumference')}
        >
          <InputNumber style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          name="hipCircumference"
          label="Perímetro cadera"
          rules={[{ required: true }]}
          style={{ flex: 1 }}
          {...findErrorByKey('hipCircumference')}
        >
          <InputNumber style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item
          name="waistHipRatio"
          label="Relación diametro cintura-caderas."
          help="diámetro cintura / diámetro caderas."
          style={{ flex: 1 }}
        >
          <InputNumber style={{ width: '100%' }} disabled />
        </Form.Item>
      </Flex>
      <Flex direction="row" justify="space-between" gap="large">
        <Form.Item
          name="totalBodyWater"
          label="ACT"
          help="Agua corporal total"
          style={{ flex: 1 }}
        >
          <InputNumber style={{ width: '100%' }} addonAfter="lt" disabled />
        </Form.Item>
        <Form.Item
          name="basalMetabolicRate"
          label="N.M.B."
          help="Nivel Metabólico Basal"
          style={{ flex: 1 }}
        >
          <InputNumber style={{ width: '100%' }} disabled />
        </Form.Item>

        <Form.Item
          name="bodyFatPercentage"
          label="Porcentaje de grasa corporal"
          rules={[{ required: true }]}
          style={{ flex: 1 }}
          {...findErrorByKey('bodyFatPercentage')}
        >
          <InputNumber style={{ width: '100%' }} addonAfter="%" />
        </Form.Item>
      </Flex>
    </Card>
  )
}

export default AnthropometryComponent
