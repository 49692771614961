import { Button, Form, notification } from 'antd'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import dayjs from 'dayjs'

import apiClient, { handleApiError } from '../../../../services/apiClient'
import FirstFormSection from './FirstFormSection'
import SecondFormSection from './SecondFormSection'
import ThirdFormSection from './ThirdFormSection'
import calculateBasalMetabolicRate from './calculateBasalMetabolicRate'
import calculateTotalBodyWater from './calculateTotalBodyWater'
import calculateIMC from './calculateIMC'

const ClinicalRecordsForm = ({
  clinicalRecord,
  consultationReasons,
  current,
  diseases,
  labExams,
  physicalExams,
  medications,
  patient,
}) => {
  const [errors, setErrors] = useState([])
  const [loading, setLoading] = useState(false)

  const [form] = Form.useForm()
  const navigate = useNavigate()
  const { id } = useParams()

  const updateFormCalculatedFields = (form, changedValues = {}) => {
    if (!form) return

    const getField = field => form.getFieldValue(field)
    const setField = (field, value) => form.setFieldValue(field, value)

    const updateField = (field, requiredFields, fx) => {
      if (requiredFields.every(field => getField(field))) {
        const values = requiredFields.reduce((acc, field) => {
          acc[field] = getField(field)
          return acc
        }, {})
        const value = fx(values)
        setField(field, value)
      } else {
        setField(field, null)
      }
    }

    // Anthropometric values
    updateField('IMC', ['weight', 'height'], ({ weight, height }) => {
      return calculateIMC(weight, height)
    })

    updateField(
      'totalBodyWater',
      ['weight', 'height', 'age'],
      ({ weight, height, age }) => {
        return calculateTotalBodyWater(weight, height * 100, age)
      }
    )

    updateField(
      'basalMetabolicRate',
      ['weight', 'height', 'age', 'sex'],
      ({ weight, height, age, sex }) => {
        return calculateBasalMetabolicRate({
          weight,
          height,
          age,
          sex,
        })
      }
    )

    // Body composition values
    updateField(
      'actualBodyFatPercentage',
      ['bodyFatPercentage'],
      ({ bodyFatPercentage }) => {
        return bodyFatPercentage
      }
    )

    updateField('actualTotalBodyWeight', ['weight'], ({ weight }) => {
      return weight
    })

    updateField('waistHipRatio', ['waistCircumference', 'hipCircumference'], ({ waistCircumference, hipCircumference }) => {
      return (waistCircumference / hipCircumference).toFixed(2)
    })

    updateField(
      'weightGoal',
      ['weight', 'bodyFatPercentage', 'bodyFatPercentageGoal'],
      ({ weight, bodyFatPercentage, bodyFatPercentageGoal }) => {
        const leanBodyMass = weight * (1 - bodyFatPercentage / 100)
        const bodyFatWeightGoal =
          (leanBodyMass * bodyFatPercentageGoal) / (100 - bodyFatPercentageGoal)
        return (bodyFatWeightGoal + leanBodyMass).toFixed(2)
      }
    )

    updateField(
      'actualLeanBodyMassPercentage',
      ['bodyFatPercentage'],
      ({ bodyFatPercentage }) => {
        return 100 - bodyFatPercentage
      }
    )

    updateField(
      'leanBodyMassPercentageGoal',
      ['bodyFatPercentageGoal'],
      ({ bodyFatPercentageGoal }) => {
        return 100 - bodyFatPercentageGoal
      }
    )

    updateField(
      'actualBodyFatWeight',
      ['weight', 'bodyFatPercentage'],
      ({ weight, bodyFatPercentage }) => {
        return ((weight * bodyFatPercentage) / 100).toFixed(2)
      }
    )

    updateField(
      'bodyFatWeightGoal',
      ['weight', 'bodyFatPercentage', 'bodyFatPercentageGoal'],
      ({ weight, bodyFatPercentage, bodyFatPercentageGoal }) => {
        const leanBodyMass = weight * (1 - bodyFatPercentage / 100)
        return (
          (leanBodyMass * bodyFatPercentageGoal) /
          (100 - bodyFatPercentageGoal)
        ).toFixed(2)
      }
    )

    updateField(
      'actualLeanBodyMassWeight',
      ['weight', 'bodyFatPercentage'],
      ({ weight, bodyFatPercentage }) => {
        return (weight * (1 - bodyFatPercentage / 100)).toFixed(2)
      }
    )

    updateField(
      'leanBodyMassWeightGoal',
      ['weight', 'bodyFatPercentage'],
      ({ weight, bodyFatPercentage }) => {
        return (weight * (1 - bodyFatPercentage / 100)).toFixed(2)
      }
    )
  }

  useEffect(() => {
    if (!clinicalRecord) return
    form.setFieldsValue(clinicalRecord)
    form.setFieldValue('age', dayjs().diff(dayjs(patient.dateOfBirth), 'year'))
    form.setFieldValue('sex', patient.sex)
    form.setFieldValue(
      'medications',
      clinicalRecord.medications.map(el => ({
        medicationId: el.id,
        description: el.ClinicalRecordMedication.description,
      }))
    )
    form.setFieldValue(
      'labExams',
      clinicalRecord.labExams.map(el => ({
        labExamId: el.id,
        description: el.ClinicalRecordLabExam.description,
      }))
    )
    form.setFieldValue(
      'physicalExams',
      clinicalRecord.physicalExams.map(el => ({
        physicalExamId: el.id,
        description: el.ClinicalRecordPhysicalExam.description,
      }))
    )
    updateFormCalculatedFields(form, { weight: clinicalRecord.weight })
    updateFormCalculatedFields(form, { height: clinicalRecord.height })
    updateFormCalculatedFields(form, {
      bodyFatPercentage: clinicalRecord.bodyFatPercentage,
    })
    updateFormCalculatedFields(form, {
      bodyFatPercentageGoal: clinicalRecord.bodyFatPercentageGoal,
    })
    updateFormCalculatedFields(form, { weightGoal: clinicalRecord.weightGoal })
  }, [clinicalRecord, form, patient])

  const handleFinish = async values => {
    setLoading(true)
    try {
      await apiClient.put(`/clinical-records/${id}`, {
        ...values,
        patientId: clinicalRecord.patientId,
      })
      notification.success({
        message: 'Éxito',
        description: 'Registro clínico guardado correctamente',
        duration: 2,
        onClose: () => navigate(`/patients/${clinicalRecord.patientId}`),
      })
    } catch (error) {
      console.log(error)
      handleApiError({ error, setErrors })
    } finally {
      setLoading(false)
    }
  }

  const handleFinishFailed = () => {
    notification.error({
      message: 'Error',
      description: 'Por favor, completa los campos requeridos',
      duration: 2,
    })
  }

  const findErrorByKey = key => {
    const error = errors.find(err => err.field === key)
    if (error) {
      return {
        validateStatus: 'error',
        help: error.message,
      }
    }
    return {}
  }

  const formatBloodPreassure = (form, changedValues) => {
    let value = changedValues.bloodPressure
    if (value) {
      value = value.toString()
      // Remove non numeric characters
      value = value.replace(/[^\d]/g, '')

      if (value.length >= 3) {
        value = value.slice(0, 3) + '/' + value.slice(3, 6)
      }
      form.setFieldsValue({ bloodPressure: value })
    }
  }

  const handleChange = (changedValues, _allValues) => {
    formatBloodPreassure(form, changedValues)
    updateFormCalculatedFields(form, changedValues)
  }

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleFinish}
      onFinishFailed={handleFinishFailed}
      onValuesChange={handleChange}
      style={{ marginTop: '2rem' }}
    >
      <div style={{ display: current === '1' ? 'block' : 'none' }}>
        <FirstFormSection
          findErrorByKey={findErrorByKey}
          consultationReasons={consultationReasons}
          diseases={diseases}
        />
      </div>
      <div style={{ display: current === '2' ? 'block' : 'none' }}>
        <SecondFormSection
          findErrorByKey={findErrorByKey}
        />
      </div>
      <div style={{ display: current === '3' ? 'block' : 'none' }}>
        <ThirdFormSection
          findErrorByKey={findErrorByKey}
          labExams={labExams}
          medications={medications}
          physicalExams={physicalExams}
        />
      </div>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading} block>
          Guardar
        </Button>
      </Form.Item>
    </Form>
  )
}

export default ClinicalRecordsForm
