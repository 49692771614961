const calculateBasalMetabolicRate = ({ sex, weight, height, age }) => {
  if (sex === 'M') {
    return 10 * weight + 6.25 * height * 100 - 5 * age + 5
  } else if (sex === 'F') {
    return 10 * weight + 6.25 * height * 100 - 5 * age - 161
  } else {
    throw new Error('sex in calculateBasalMetabolicRate must be M or F')
  }
}

export default calculateBasalMetabolicRate
