import CRUDFormComponent from '../../components/CRUDFormComponent'

const MedicationsForm = () => {
  const fields = [{ key: 'name', label: 'Nombre', type: 'string' }]

  return (
    <>
      <CRUDFormComponent
        entityName={{ singular: 'Medicamento' }}
        fields={fields}
        entityUrl="/medications"
      />
    </>
  )
}

export default MedicationsForm
