import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { useNavigate, useParams } from 'react-router-dom'
import CRUDTableComponent from '../../../components/CRUDTableComponent'
import { useEffect, useState } from 'react'
import apiClient, { handleApiError } from '../../../services/apiClient'
import downloadPDF from './downloadPDF'
import PatientInformation from './PatientInformation'
import DietaryHabitsInformation from './DietaryHabitsInformation'
import FamilyBackground from './FamilyBackground'

const PatientsDetail = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [patient, setPatient] = useState({})
  const [communes, setCommunes] = useState([])
  const [healthInsurances, setHealthInsurances] = useState([])
  const [physicalActivityLevels, setPhysicalActivityLevels] = useState([])
  const [professions, setProfessions] = useState([])

  const tableColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Fecha de Atención',
      dataIndex: 'createdAt',
      render: value => {
        return dayjs(value).format('D [de] MMMM [del] YYYY')
      },
    },
    {
      title: 'Ver ficha',
      dataIndex: 'id',
      render: id => {
        return (
          <Button
            onClick={() => navigate(`/clinical-records/${id}`)}
            icon={<PlusOutlined />}
          >
            Ver ficha
          </Button>
        )
      },
    },
    {
      title: 'Descargar ficha',
      dataIndex: 'id',
      render: id => {
        return (
          <Button
            onClick={async () =>
              await downloadPDF(`/clinical-records/${id}/generate-pdf`)
            }
            icon={<PlusOutlined />}
          >
            Descargar
          </Button>
        )
      },
    },
  ]

  const fetchPatient = async id => {
    try {
      const patient = await apiClient.get(`/patients/${id}`)
      setPatient(patient.data)
    } catch (error) {
      console.error('Error fetching patient', error)
    }
  }

  useEffect(() => {
    fetchPatient(id)
  }, [id])

  const fetchInformation = async patientId => {
    try {
      const [communes, healthInsurances, physicalActivityLevels, professions] =
        await Promise.all([
          apiClient.get('/communes'),
          apiClient.get('/health-insurances'),
          apiClient.get('/physical-activity-levels'),
          apiClient.get('/professions'),
        ])

      setCommunes(communes.data)
      setHealthInsurances(healthInsurances.data)
      setPhysicalActivityLevels(physicalActivityLevels.data)
      setProfessions(professions.data)
    } catch (error) {
      handleApiError({ error })
    }
  }

  useState(() => {
    fetchInformation()
  }, [])
  return (
    <>
      <PatientInformation
        patient={patient}
        physicalActivityLevels={physicalActivityLevels}
        communes={communes}
        healthInsurances={healthInsurances}
        professions={professions}
      />

      <CRUDTableComponent
        tableColumns={tableColumns}
        entityName={{
          singular: 'Registro Clínico',
          plural: `Registros Clínicos del paciente ${id}`,
        }}
        entityUrl={`/clinical-records?patientId=${id}`}
        tableColumnsActions={{ edit: false, delete: true }}
      />

      <DietaryHabitsInformation dietaryHabits={patient?.dietaryHabit} />
      <FamilyBackground
        familyBackground={patient?.familyBackground?.familyBackground}
      />
    </>
  )
}

export default PatientsDetail
