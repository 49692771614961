import CRUDFormComponent from '../../components/CRUDFormComponent'

const PhysicalActivityLevelsForm = () => {
  const fields = [
    { key: 'level', label: 'Nivel', type: 'string' },
    { key: 'caloriesPerDay', label: 'Calorías por día', type: 'number' },
  ]

  return (
    <>
      <CRUDFormComponent
        entityName={{ singular: 'Niveles de actividad física' }}
        fields={fields}
        entityUrl="/physical-activity-levels"
      />
    </>
  )
}

export default PhysicalActivityLevelsForm
